import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
// components
import AppBar from "./components/AppBar";
import VerticalTabs from "./components/Vertical.Tabs";
import Enrollments from "./pages/enrollments";
import Login from "./pages/login";
import { AuthProvider } from "./contexts/User";
import Logout from "./pages/Logout";
import UserId from "./pages/user-activities/user.id";

function App() {
  return (
    <div className="body user-card-colors">
      <AuthProvider>
        <Router>
          <AppBar />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<VerticalTabs />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/activity" element={<UserId />} />
          </Routes>
          <Footer />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
