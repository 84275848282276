import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import axios from "axios";
import Loader from "../../components/Loader";
import { URLS } from "../../constants/API";

const NonBiometrics = () => {
  const [data, setData] = useState([]);
  const [biometricsCount, setBiometricsCount] = useState(0);
  const [electronicsCount, setElectronicsCount] = useState(0);

  useEffect(() => {
    // Fetch data from the endpoint
    fetch(`${URLS.BASE}/overall-enrollments`)
      .then((response) => response.json())
      .then((responseData) => {
        // Assuming responseData is an array of objects with the specified fields
        setData(responseData);
        // Count Biometrics and Electronics
        const biometrics = responseData.filter((item) => item.simprintsGui);
        const electronics = responseData.filter((item) => !item.simprintsGui);
        setBiometricsCount(biometrics.length);
        setElectronicsCount(electronics.length);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      <Card
        className="card"
        style={{
          backgroundColor: "var(--clr-primary-5)",
          color: "var(--clr-white)",
        }}
      >
        <Card.Body>
          <Card.Title className="card-title">Non Biometrics Enrollment</Card.Title>
        
          <Card.Text className="card-icon">
           {electronicsCount}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default NonBiometrics;
