import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { URLS } from "../../constants/API";
import { SpinnerTwo } from "../../components/Loader";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const AntenatalsCSV = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editRecord, setEditRecord] = useState(null); // Record to be edited
  const [editedValue, setEditedValue] = useState("");

  useEffect(() => {
    // Fetch data from the endpoint
    fetch(`${URLS.BASE}/antenantals-receipts-data`)
      .then((response) => response.json())
      .then((responseData) => {
        // Assuming responseData is an array of objects with the specified fields
        setData(responseData);
        setLoading(false); // Set loading to false when data is loaded
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const downloadCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      "Beneficiary ID,Created At,SID GUID,SID Session ID,Beneficiary Sex,Vaccine Dose Number,Attendant Name,Attendant ID,SID User ID\n" +
      data
        .map(
          (item) =>
            `${item.beneficiaryId},${item.createdAt},${item.simprintsGui},${
              item.simSessionId
            },${item.sex},"${item.antenantals
              .map(
                (antenantal) => `${antenantal.vaccineName} - ${antenantal.dose}`
              )
              .join(", ")}",${item.attendant.fullName},${item.attendant.id},${
              item.attendant.fullName
            }`
        )
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "dashboard_data.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handleEditClick = (record) => {
    // Set the record to be edited when the "Edit" button is clicked
    setEditRecord(record);
    // Initialize the edited value with the current value from the record
    setEditedValue(record.attendant.fullName); // You can replace this with the field you want to edit
  };

  const handleSaveEdit = () => {
    // Update the edited value in the data
    const updatedData = data.map((item) =>
      item === editRecord
        ? { ...item, attendant: { ...item.attendant, fullName: editedValue } } // Update the fullName field
        : item
    );
    setData(updatedData);

    // Close the modal and reset editRecord and editedValue
    setEditRecord(null);
    setEditedValue("");
  };

  return (
    <div>
      <Button
        variant="primary"
        onClick={downloadCSV}
        style={{
          backgroundColor: "var(--clr-primary-5)",
          color: "var(--clr-white)",
          border: "none",
          cursor: "pointer",
          padding: "0.5rem",
          margin: "0.8rem",
        }}
      >
        Download CSV
      </Button>
      {loading ? (
        <SpinnerTwo />
      ) : (
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th className="table-header">Attendant Name</th>

              <th className="table-header">Beneficiary ID</th>
              <th className="table-header">Created At</th>

              <th className="table-header">Beneficiary Sex</th>
              <th className="table-header">Services Delivered</th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter(
                (item) =>
                  item.sex === "Female" &&
                  item.antenantals &&
                  item.antenantals.length > 0
              )
              .map((item, index) => (
                <tr key={index}>
                  <td>{item.attendant.fullName}</td>

                  <td>{item.beneficiaryId}</td>
                  <td>{item.createdAt}</td>
                  <td>{item.sex}</td>
                  <td>
                    {item.antenantals.map((antenantal, antenantalIndex) => (
                      <div key={antenantalIndex}>
                        -Pregnancy Status: {antenantal.pregnancyStatus}, -E.D.D:{" "}
                        {antenantal.expectedDateOfDelivery}
                      </div>
                    ))}
                  </td>
                  <td>
                    {/* "Edit" button for each row */}
                    <Button
                      variant="primary"
                      onClick={() => handleEditClick(item)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}

      <Modal show={!!editRecord} onHide={() => setEditRecord(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="editedValue">
            <Form.Label>Edit Attendant Name</Form.Label>
            <Form.Control
              type="text"
              value={editedValue}
              onChange={(e) => setEditedValue(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditRecord(null)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .full-width-table {
          width: 409%; /* Change to 100% for full width */
          margin: 20px; /* Add margin to the table */
          padding: 10px; /* Add padding to the table */
          border-collapse: collapse; /* Collapse table borders */
        }

        .table-header {
          background-color: lightblue; /* Light blue background for headings */
          padding: 8px; /* Add padding to headings */
        }

        /* Optional: Style table rows and cells */
        tr:nth-child(even) {
          background-color: #f2f2f2; /* Alternate row background color */
        }

        td,
        th {
          border: 1px solid #ddd; /* Add borders to cells */
          padding: 8px; /* Add padding to cells */
        }
      `}</style>
    </div>
  );
};

export default AntenatalsCSV;
