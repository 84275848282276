import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import axios from "axios";
import Loader from "../components/Loader";
import { URLS } from "../constants/API";

const WeeklyEnrollments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [weeklyEnrollmentsCount, setWeeklyEnrollmentsCount] = useState(0);

  const fetchWeeklyEnrollments = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${URLS.BASE}/patients`);

      if (response.status === 200) {
        const sortedData = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const currentDate = new Date();
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(
          currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
        ); // Set to Monday of the current week
        startOfWeek.setHours(0, 0, 0, 0); // Set time to midnight

        const endOfWeek = new Date(currentDate);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to Sunday of the current week
        endOfWeek.setHours(23, 59, 59, 999); // Set time to the last millisecond of the day

        const weeklyEnrollments = sortedData.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= startOfWeek && itemDate <= endOfWeek;
        });

        setWeeklyEnrollmentsCount(weeklyEnrollments.length);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Use an effect to fetch data initially and set up the interval for refreshing on Mondays.
  useEffect(() => {
    async function fetchData() {
      try {
        await fetchWeeklyEnrollments();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();

    // Set up an interval to refresh data every Monday.
    const refreshInterval = setInterval(() => {
      const currentDate = new Date();
      // Check if it's Monday (day 1 of the week) and it's not midnight yet (to avoid multiple refreshes on Monday).
      if (currentDate.getDay() === 1 && currentDate.getHours() > 0) {
        fetchData();
      }
    }, 1000 * 60 * 60); // Check every hour (you can adjust the interval as needed).

    // Cleanup the interval when the component unmounts.
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Card
      className="card"
      style={{
        backgroundColor: "var(--clr-grey-1)",
        color: "var(--clr-white)",
      }}
    >
      <Card.Body>
        <Card.Title className="card-title">This Week's Enrollments</Card.Title>
        {isLoading ? (
          <Loader />
        ) : (
          <Card.Text className="card-icon">{weeklyEnrollmentsCount}</Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default WeeklyEnrollments;
