import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import axios from "axios";
import Loader from "../../components/Loader";
import { URLS } from "../../constants/API";

const Male = ({ startDate, endDate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [filteredCount, setFilteredCount] = useState(0);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${URLS.BASE}/patients`);

      if (response.status === 200) {
        const patients = response.data;
        setTotalCount(countMale(patients));

        const filteredPatients = patients.filter((item) => {
          const dateOfBirth = new Date(item.ageGroup);
          return (
            item.sex === "Male" &&
            dateOfBirth >= startDate &&
            dateOfBirth <= endDate
          );
        });

        setFilteredCount(countMale(filteredPatients));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const countMale = (patients) => {
    return patients.filter((item) => item.sex === "Male").length;
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  return (
    <Card
      className="container"
      style={{
        backgroundColor: "var(--clr-grey-1)",
        color: "var(--clr-white)",
      }}
    >
      <Card.Body>
        <Card.Title className="card-title">Male Patients</Card.Title>
        {isLoading ? (
          <Loader />
        ) : (
          <Card.Text className="card-icon">
            {startDate && endDate ? filteredCount : totalCount}
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default Male;
