import React, { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import axios from "axios";
import { SpinnerTwo } from "../../components/Loader";
import { URLS } from "../../constants/API";

const LineGraph = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${URLS.BASE}/patients`);
        const transformedData = transformData(response.data);
        setData(transformedData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const transformData = (originalData) => {
    const currentDate = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(currentDate.getMonth() - 2); // Subtract 2 to get the last 3 months

    const filteredData = originalData.filter((patient) => {
      return (
        patient.vaccinations.some(
          (vaccination) =>
            new Date(vaccination.dateOfVaccination) >= threeMonthsAgo
        ) ||
        patient.diagnoses.some(
          (diagnosis) => new Date(diagnosis.dateOfDiagnosis) >= threeMonthsAgo
        ) ||
        patient.antenantals.some(
          (antenatal) => new Date(antenatal.routineVisitDate) >= threeMonthsAgo
        )
      );
    });
    const groupedData = originalData.reduce((acc, patient) => {
      // Process vaccinations
      patient.vaccinations.forEach((vaccination) => {
        const date = vaccination.dateOfVaccination.split("T")[0];
        if (!acc[date]) {
          acc[date] = {
            date,
            vaccinations: 0,
            diagnoses: 0,
            antenantals: 0,
          };
        }
        acc[date].vaccinations++;
      });

      // Process diagnoses
      patient.diagnoses.forEach((diagnosis) => {
        const date = diagnosis.dateOfDiagnosis.split("T")[0];
        if (!acc[date]) {
          acc[date] = {
            date,
            vaccinations: 0,
            diagnoses: 0,
            antenantals: 0,
          };
        }
        acc[date].diagnoses++;
      });

      //   process antenatals
      patient.antenantals.forEach((antenatal) => {
        const date = antenatal.routineVisitDate.split("T")[0];
        if (!acc[date]) {
          acc[date] = {
            date,
            vaccinations: 0,
            diagnoses: 0,
            antenantals: 0,
          };
        }
        acc[date].antenantals++;
      });

      return acc;
    }, {});

    const sortedData = Object.values(groupedData).sort((a, b) =>
      a.date.localeCompare(b.date)
    );

    return sortedData;
  };

  const formatMonth = (date) => {
    return new Date(date).toLocaleString("default", { month: "short" });
  };

  if (isLoading) {
    return <SpinnerTwo />;
  }

  return (
    <ResponsiveContainer width="100%" height={420}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          interval={16}
          angle={-45}
          textAnchor="end"
          tickCount={data.length > 90 ? 3 : data.length}
          tickFormatter={formatMonth}
          domain={[data[data.length - 1].date, "dataMax"]}
        />
        <YAxis ticks={[10, 20, 30, 40, 50, 60]} />
        <Tooltip />
        <Legend />
        <Line
          dataKey="vaccinations"
          name="Number of Vaccinations"
          stroke="blue"
          dot={{ stroke: "blue", strokeWidth: 0.5, r: 2 }} 
        />
        <Line dataKey="diagnoses" name="Number of Diagnoses" stroke="green" />
        <Line dataKey="antenantals" name="Number Of Antenatals" stroke="red" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineGraph;
