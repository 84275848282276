import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Row, Col, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// components
import Enrollments from "../pages/enrollments";
import "../App.css";
import Vaccinations from "../pages/vaccinations/vaccinations";
import Antenatals from "../pages/antenatal/antenatals";
import Diagnosis from "../pages/diagnosis/diagnosis";
import WeeklyEnrollments from "../pages/weekly.enrolments";
import DailyEnrollments from "../pages/daily.enrollments";
import Loader from "./Loader";
import FirstDose from "../pages/vaccinations/first.dose";
import SecondDose from "../pages/vaccinations/second.dose";
import ThirdDose from "../pages/vaccinations/third.dose";
import useRequireAuth from "../contexts/Auth";
import UserId from "../pages/user-activities/user.id";
import LineGraph from "../pages/graphs/line.graph";
import EnrollmentGraph from "../pages/graphs/enrollment.graph";
import Female from "../pages/gender.js/female";
import Male from "../pages/gender.js/male";
import Conditions from "../pages/diagnosis/conditions";
import LabTests from "../pages/diagnosis/diagnosis.data";
import AntenatalsCSV from "../pages/antenatal/antenatal.csv";
import DiagnosisData from "../pages/diagnosis/diagnosis.data";
import VaccinationCSV from "../pages/vaccinations/vaccination.csv";
import NonBiometrics from "../pages/gender.js/non.biometrics";
import Biometrics from "../pages/gender.js/biometrics";

function VerticalTabs() {
  const [key, setKey] = useState("home");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useRequireAuth();

  return (
    <Container className="vertical-tabs-container">
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mt-2"
        fill
      >
        <Tab eventKey="summary" title="Summary Information">
          <div className="date-picker-container mt-4">
            <label className="date-picker-label">Select Date Range</label>
            <div className="date-picker-row">
              <div className="date-picker-group">
                <label className="date-picker-sublabel">Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Select Start Date"
                  className="date-picker-input"
                />
              </div>
              <div className="date-picker-group">
                <label className="date-picker-sublabel">End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="Select End Date"
                  className="date-picker-input"
                />
              </div>
            </div>
          </div>

          <Row className="section mainCard">
            <Col xs={12} md={4} className="mt-4">
              <Enrollments startDate={startDate} endDate={endDate} />
            </Col>
            <Col xs={12} md={4} className="mt-4">
              <DailyEnrollments />
            </Col>
            <Col xs={12} md={4} className="mt-4">
              <WeeklyEnrollments />
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12} md={4} className="mb-4">
              <Antenatals startDate={startDate} endDate={endDate} />
            </Col>
            <Col xs={12} md={4} className="mb-4">
              <Vaccinations startDate={startDate} endDate={endDate} />
            </Col>
            <Col xs={12} md={4} className="mb-4">
              <Diagnosis startDate={startDate} endDate={endDate} />
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12} md={4} className="mb-4">
              <FirstDose startDate={startDate} endDate={endDate} />
            </Col>
            <Col xs={12} md={4} className="mb-4">
              <SecondDose startDate={startDate} endDate={endDate} />
            </Col>
            <Col xs={12} md={4} className="mb-4">
              <ThirdDose startDate={startDate} endDate={endDate} />
            </Col>
          </Row>

          <Row className="section">
            <Col xs={12} md={6} className="mb-4">
              <Female startDate={startDate} endDate={endDate} />
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <Male startDate={startDate} endDate={endDate} />
            </Col>
          </Row>

          <Row className="section">
            <Col xs={12} md={6} className="mb-4">
              <NonBiometrics startDate={startDate} endDate={endDate} />
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <Biometrics />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} className="mb-4">
              <EnrollmentGraph />
            </Col>
            <Col xs={12} md={12} className="mb-4">
              <LineGraph />
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="activity" title="CHP Activity">
          <div className="date-picker-container">
            <label className="date-picker-label">Select Date Range</label>
            <div className="date-picker-row">
              <div className="date-picker-group">
                <label className="date-picker-sublabel">Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Select Start Date"
                  className="date-picker-input"
                />
              </div>
              <div className="date-picker-group">
                <label className="date-picker-sublabel">End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="Select End Date"
                  className="date-picker-input"
                />
              </div>
            </div>
          </div>
          <Row className="section">
            <Col className="mb-4">
              <UserId startDate={startDate} endDate={endDate} />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="consent" title="Diagnosis">
          <Row>
            <Col xs={12} className="mb-4">
              <DiagnosisData />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="captuure" title="Antenatal Care">
          <Row>
            <Col xs={12} className="mb-4">
              <AntenatalsCSV />
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="tech" title="Vaccination">
          <Row>
            <Col xs={12} className="mb-4">
              <VaccinationCSV />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default VerticalTabs;
