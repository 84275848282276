import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import axios from "axios";
import Loader from "../components/Loader";
import { URLS } from "../constants/API";

const Enrollments = ({ startDate, endDate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [filteredCount, setFilteredCount] = useState(0);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${URLS.BASE}/patients`);

      if (response.status === 200) {
        const userData = response.data;
        setTotalCount(userData.length);

        const enrollmentsInRange = userData.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= startDate && itemDate <= endDate;
        });

        setFilteredCount(enrollmentsInRange.length);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [startDate, endDate]);

  return (
    <Card
      className="card"
      style={{
        backgroundColor: "var(--clr-red-dark)",
        color: "var(--clr-white)",
      }}
    >
      <Card.Body>
        <Card.Title className="card-title">Total Patients</Card.Title>
        {isLoading ? (
          <Loader />
        ) : (
          <Card.Text className="card-icon">
            {startDate && endDate ? filteredCount : totalCount}
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default Enrollments;
