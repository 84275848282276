import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import axios from "axios";
import Loader from "../../components/Loader";
import { URLS } from "../../constants/API";

const ThirdDose = ({ startDate, endDate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [filteredCount, setFilteredCount] = useState(0);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${URLS.BASE}/patients`
      );

      if (response.status === 200) {
        const patients = response.data;
        setTotalCount(countTotalSecondDoseVaccinations(patients));

        const filteredPatients = patients.filter((patient) => {
          return patient.vaccinations.some((vaccination) => {
            const vaccinationDate = new Date(vaccination.dateOfVaccination);
            return (
              vaccination.dose === "3rd" &&
              vaccinationDate >= startDate &&
              vaccinationDate <= endDate
            );
          });
        });

        setFilteredCount(countTotalSecondDoseVaccinations(filteredPatients));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const countTotalSecondDoseVaccinations = (patients) => {
    return patients.reduce((count, patient) => {
      const secondDoseVaccinations = patient.vaccinations.filter(
        (vaccination) => vaccination.dose === "3rd"
      );
      return count + secondDoseVaccinations.length;
    }, 0);
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  return (
    <Card
      className="container"
      style={{
        backgroundColor: "var( --clr-red-dark)",
        color: "var(--clr-white)",
      }}
    >
      <Card.Body>
        <Card.Title className="card-title">Third Dose Vaccination</Card.Title>
        {isLoading ? (
          <Loader />
        ) : (
          <Card.Text className="card-icon">
            {startDate && endDate ? filteredCount : totalCount}
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default ThirdDose;
