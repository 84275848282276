import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SpinnerTwo } from "../../components/Loader";

const UserId = ({ startDate, endDate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userDataList, setUserDataList] = useState([]);
  const [error, setError] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [filteredCount, setFilteredCount] = useState(0);

  const signUpIds = [
    "32ea6580-c0ca-41fd-8ef7-09798fd2ad46",
    "c9249a9c-c32a-4089-b53f-07d3cd859317",
    "78739c82-709a-4c05-b37c-a4bc34c39b9e",
    "e3833abb-17c2-4092-b732-78540dfc4952",
    "5707391b-18c4-4e13-8074-65692f996c99",
    "e7393abf-e76d-4326-8726-f64aa2443f4f",
    "6636465c-219f-4b28-bff1-a44982eb5434",
    "48a459a7-37f5-470c-8167-c288fe18ceb2",
    "b7329ad9-e0ed-4631-868c-543cc542aaa9",
    "c5dd3c1b-5dda-4df9-9655-69032652e74d",
  ];

  const userNames = {
    "32ea6580-c0ca-41fd-8ef7-09798fd2ad46": {
      firstName: "Grace",
      lastName: "Tabuzibwa",
    },
    "48a459a7-37f5-470c-8167-c288fe18ceb2": {
      firstName: "Kafeero",
      lastName: "Edris",
    },
    "5707391b-18c4-4e13-8074-65692f996c99": {
      firstName: "Bernard  Mark",
      lastName: "Ochoite",
    },
    "6636465c-219f-4b28-bff1-a44982eb5434": {
      firstName: "Rogers",
      lastName: "Ndayisaba",
    },
    "78739c82-709a-4c05-b37c-a4bc34c39b9e": {
      firstName: "Shamidah",
      lastName: "Nakisuyi",
    },
    "b7329ad9-e0ed-4631-868c-543cc542aaa9": {
      firstName: "Moses",
      lastName: "Wamala mowa",
    },
    "c5dd3c1b-5dda-4df9-9655-69032652e74d": {
      firstName: "Dorothy",
      lastName: "Kirunda",
    },
    "c9249a9c-c32a-4089-b53f-07d3cd859317": {
      firstName: "Alex",
      lastName: "Wanasolo",
    },
    "e3833abb-17c2-4092-b732-78540dfc4952": {
      firstName: "Samanya ",
      lastName: "Simon",
    },
    "e7393abf-e76d-4326-8726-f64aa2443f4f": {
      firstName: "Josephine Kirya",
      lastName: "Tembo",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const newData = [];

        for (const signUpId of signUpIds) {
          const response = await axios.get(
            `https://mobi-be-production.up.railway.app/${signUpId}/patients`
          );

          let filteredPatients = response.data;
          const userData = userNames[signUpId];

          let vaccinationsCount = 0;
          let antenatalsCount = 0;
          let diagnosisCount = 0;

          if (response.data && Array.isArray(response.data)) {
            const patients = response.data;
            vaccinationsCount = patients.reduce((count, patient) => {
              return count + patient.vaccinations.length;
            }, 0);

            antenatalsCount = patients.reduce((count, patient) => {
              return count + patient.antenantals.length;
            }, 0);

            diagnosisCount = patients.reduce((count, patient) => {
              return count + patient.diagnoses.length;
            }, 0);
          }

          if (startDate && endDate) {
            filteredPatients = filteredPatients.filter((patient) => {
              return (
                patient.vaccinations.some((vaccination) => {
                  const vaccinationDate = new Date(
                    vaccination.dateOfVaccination
                  );
                  return (
                    vaccinationDate >= startDate && vaccinationDate <= endDate
                  );
                }) ||
                patient.diagnoses.some((diagnosis) => {
                  const diagnosisDate = new Date(diagnosis.dateOfDiagnosis);
                  return diagnosisDate >= startDate && diagnosisDate <= endDate;
                }) ||
                patient.antenantals.some((antenatal) => {
                  const antenatalDate = new Date(antenatal.routineVisitDate);
                  return antenatalDate >= startDate && antenatalDate <= endDate;
                })
              );
            });
          }

          newData.push({
            signUpId,
            patients: filteredPatients,
            patientsEnrolledCount: filteredPatients.length,
            vaccinationsCount,
            antenatalsCount,
            diagnosisCount,
          });
        }

        setUserDataList(newData);
        setIsLoading(false);
      } catch (error) {
        setError(`Error fetching data: ${error.message}`);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  return (
    <Container className="section mt-4">
      {isLoading ? (
        <SpinnerTwo />
      ) : (
        <Row>
          {userDataList.map((userData, index) => (
            <Col key={userData.signUpId} xs={12} md={6} lg={4}>
              <Card
                className="container mb-4"
                style={{ backgroundColor: `var(--color-${(index % 4) + 1})` }}
                text="white"
              >
                <Card.Body>
                  <Card.Title className="card-title">
                    {userNames[userData.signUpId]?.firstName}{" "}
                    {userNames[userData.signUpId]?.lastName}
                  </Card.Title>
                  {userData &&
                  userData.patients &&
                  Array.isArray(userData.patients) ? (
                    <Card.Text className="card-icon">
                      <br />
                      {userData.patientsEnrolledCount}
                    </Card.Text>
                  ) : (
                    <SpinnerTwo />
                  )}
                  <Card.Text
                    style={{
                      color: "var(--clr-grey-10)",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    Vaccinations: {userData.vaccinationsCount}
                  </Card.Text>
                  <Card.Text
                    style={{
                      color: "var(--clr-grey-10)",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    Antenatals: {userData.antenatalsCount}
                  </Card.Text>

                  <Card.Text
                    style={{
                      color: "var(--clr-grey-10)",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    Diagnosis: {userData.diagnosisCount}
                  </Card.Text>

                  
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      {error && <p>{error}</p>}
    </Container>
  );
};

export default UserId;
