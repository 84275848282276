import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import axios from "axios";
import Loader from "../../components/Loader";
import { URLS } from "../../constants/API";
import '../../App.css'

const Vaccinations = ({ startDate, endDate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [filteredCount, setFilteredCount] = useState(0);

  const fetchVaccinationData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${URLS.BASE}/vaccinations/all`);

      if (response.status === 200) {
        const vaccinationData = response.data;
        setTotalCount(vaccinationData.length);

        const vaccinationsInRange = vaccinationData.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= startDate && itemDate <= endDate;
        });

        setFilteredCount(vaccinationsInRange.length);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVaccinationData();
  }, [startDate, endDate]);

  return (
    <Card
      className="card"
      style={{
        backgroundColor: "var(--clr-red-dark)",
        color: "var(--clr-white)",
      }}
    >
      <Card.Body>
        <Card.Title className="card-title">Vaccinations Made</Card.Title>
        {isLoading ? (
          <Loader />
        ) : (
          <Card.Text className="card-icon">
            {startDate && endDate ? filteredCount : totalCount}
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default Vaccinations;
