import React, { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import axios from "axios";
import { SpinnerTwo } from "../../components/Loader";
import { URLS } from "../../constants/API";

const EnrollmentGraph = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${URLS.BASE}/patients`);
        const transformedData = transformData(response.data);
        setData(transformedData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const transformData = (originalData) => {
    const currentDate = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(currentDate.getMonth() - 4);

    const filteredData = originalData.filter((patient) => {
      return (
        patient.vaccinations.some(
          (vaccination) =>
            new Date(vaccination.dateOfVaccination) >= sixMonthsAgo
        ) ||
        patient.diagnoses.some(
          (diagnosis) => new Date(diagnosis.dateOfDiagnosis) >= sixMonthsAgo
        ) ||
        patient.antenantals.some(
          (antenatal) => new Date(antenatal.routineVisitDate) >= sixMonthsAgo
        )
      );
    });

    const groupedData = filteredData.reduce((acc, patient) => {
      const date = patient.createdAt.split("T")[0];
      if (!acc[date]) {
        acc[date] = {
          date,
          count: 0,
        };
      }
      acc[date].count++;
      return acc;
    }, {});

    const sortedData = Object.values(groupedData).sort((a, b) =>
      a.date.localeCompare(b.date)
    );

    return sortedData;
  };

  const formatMonth = (date) => {
    return new Date(date).toLocaleString("default", { month: "short" });
  };

  if (isLoading) {
    return <SpinnerTwo />;
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="gradient" x1="1" y2="1">
            <stop offset="10%" stopColor="var(--clr-grey-1)" stopOpacity={1} />
            <stop
              offset="100%"
              stopColor="var(--clr-primary-5)"
              stopOpacity={0.1}
            />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          tickFormatter={formatMonth}
          interval={13}
          angle={-45}
          textAnchor="end"
        />
        <YAxis ticks={[10, 20, 30, 40]} />
        <Area
          dataKey="count"
          name="Number of Enrollments"
          type="monotone"
          fill="url(#gradient)"
          style={{ marginTop: "1rem" }}
        />
        <Tooltip />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default EnrollmentGraph;
