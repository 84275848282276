import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import axios from "axios";
import Loader from "../components/Loader"
import { URLS } from '../constants/API';


const DailyEnrollments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [counts, setCounts] = useState({
    patientsEnrolledCount: 0,
    patientsEnrolledCountt: 0,
    dailyEnrollmentsCount: 0,
    monthlyEnrollmentsCount: 0,
    weeklyEnrollmentsCount: 0,
    totalVaccinations: 0,
    totalDiagnosis: 0,
    totalAntenatal: 0,
  });
  const {
    patientsEnrolledCount,
    patientsEnrolledCountt,
    dailyEnrollmentsCount,
    monthlyEnrollmentsCount,
    weeklyEnrollmentsCount,
    totalVaccinations,
    totalDiagnosis,
    totalAntenatal,
  } = counts;

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${URLS.BASE}/patients`
      );

      if (response.status === 200) {
        const sortedData = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setCounts((prevCounts) => ({
          ...prevCounts,
          patientsEnrolledCount: sortedData.length,
        }));

        const currentDate = new Date();

        // Last 30 days filtering
        const thirtyDaysAgo = new Date(currentDate);
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);
        const monthlyEnrollments = sortedData.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= thirtyDaysAgo && itemDate <= currentDate;
        });
        setCounts((prevCounts) => ({
          ...prevCounts,
          monthlyEnrollmentsCount: monthlyEnrollments.length,
        }));

        // Last 7 days filtering
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        const weeklyEnrollments = sortedData.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= sevenDaysAgo && itemDate <= currentDate;
        });
        setCounts((prevCounts) => ({
          ...prevCounts,
          weeklyEnrollmentsCount: weeklyEnrollments.length,
        }));

        // DAILY ENROLLMENTS count
        const dailyEnrollments = sortedData.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return (
            itemDate >= thirtyDaysAgo &&
            itemDate <= currentDate &&
            itemDate.getDate() === currentDate.getDate() &&
            itemDate.getMonth() === currentDate.getMonth() &&
            itemDate.getFullYear() === currentDate.getFullYear()
          );
        });
        setCounts((prevCounts) => ({
          ...prevCounts,
          dailyEnrollmentsCount: dailyEnrollments.length,
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchVaccinationData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://mobi-be-production.up.railway.app/vaccinations/all`
      );

      if (response.status === 200) {
        const sortedData = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        const startDate = new Date(currentYear, currentMonth, 1);
        const endDate = new Date(currentYear, currentMonth + 1, 0);

        const monthlyVaccinations = sortedData.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= startDate && itemDate <= endDate;
        });

        setCounts((prevCounts) => ({
          ...prevCounts,
          totalVaccinations: monthlyVaccinations.length,
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDiagnosis = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://mobi-be-production.up.railway.app/diagnosis/all`
      );

      if (response.status === 200) {
        const sortedData = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        const startDate = new Date(currentYear, currentMonth, 1);
        const endDate = new Date(currentYear, currentMonth + 1, 0);

        const monthlyDiagnosis = sortedData.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= startDate && itemDate <= endDate;
        });

        setCounts((prevCounts) => ({
          ...prevCounts,
          totalDiagnosis: monthlyDiagnosis.length,
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAntenatal = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://mobi-be-production.up.railway.app/antenantals/all`
      );

      if (response.status === 200) {
        const sortedData = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        const startDate = new Date(currentYear, currentMonth, 1);
        const endDate = new Date(currentYear, currentMonth + 1, 0);

        const monthlyAntenatal = sortedData.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= startDate && itemDate <= endDate;
        });

        setCounts((prevCounts) => ({
          ...prevCounts,
          totalAntenatal: monthlyAntenatal.length,
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await fetchUsers();
        await fetchVaccinationData();
        await fetchDiagnosis();
        await fetchAntenatal();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);
  return (
    <Card
      className="card"
      style={{ backgroundColor: "var(--clr-primary-6)", color: "var(--clr-white)" }}
    >
     <Card.Body>
        <Card.Title className="card-title">Today's Enrollments</Card.Title>
        {isLoading ? (
          <Loader />
        ) : (
          <Card.Text className="card-icon">{dailyEnrollmentsCount}</Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default DailyEnrollments;
